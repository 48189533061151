.streaming h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 2rem;
  color: #5c3c8e; /* Subdued purple shade for headings */
}

.streaming ul {
  background-color: #3c2c5a; /* Dark purple shade */
  color: #e1d5e7; /* A light purple-tinted white for text, ensuring good contrast */
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  list-style-type: none;
}

.streaming li {
  background-color: #4a355f; /* Slightly lighter purple shade for list items */
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.streaming h3 {
  margin-bottom: 0.5rem;
  color: #e0b0ff; /* Brighter lavender shade for better contrast against the dark purple background */
}

.streaming p {
  font-family: "Signika", sans-serif;
  color: #d3cdd8; /* Slightly purple-tinted light gray for general text */
}

/* Style 2 with hover effect */
/* 
body {
    background-color: #282c34;
    color: #e0e0e0;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 2rem;
    color: black;
    text-align: center;
  }
  
  ul {
    background-color: #3c4049;
    color: #fff;
    border-radius: 10px;
    padding: 2rem;
    margin: 2rem;
    max-width: 800px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    list-style-type: none;
  }
  
  li {
    background-color: #454d5d;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  
  li:hover {
    background-color: #53627c;
  }
  
  h3 {
    margin-bottom: 0.5rem;
    color: #ff7f50;
  }
  
  p {
    font-family: 'Signika', sans-serif;
    color: #d3d3d3;
  }
  
  @media screen and (max-width: 800px) {
    ul {
      margin: 1rem;
      padding: 1rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  }
   */
